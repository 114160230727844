@import '@hh.ru/magritte-ui/tokens';

.card-footer {
    display: flex;
    justify-content: space-between;
}

.adv-click-warning-text {
    color: @magritte-color-background-warning-heavy;
}

.unimportant-text {
    color: @magritte-color-component-action-icon-contrast-secondary;
}
