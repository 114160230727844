/* stylelint-disable max-nesting-depth */
/* stylelint-disable selector-max-compound-selectors */

@import '@hh.ru/magritte-design-tokens/tokens';

.vacancy-name-wrapper {
    overflow-wrap: break-word;
}

.custom-color-magritte-link {
    a {
        &:hover {
            --magritte-ui-text-color-override: @magritte-color-text-primary;
        }

        &:visited {
            span {
                span {
                    /* stylelint-disable-next-line declaration-no-important */
                    color: @magritte-color-text-secondary !important;
                }
            }
        }
    }
}
