.modal-no-scroll() {
    position: relative;
    overflow: hidden;
    user-select: none;
}

.modal-no-scroll-ios() {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    position: fixed;
    height: 100%;
}
