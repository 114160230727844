@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/colors';
@import '@hh.ru/magritte-ui/tokens';

.vacancy-name {
    overflow-wrap: break-word;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: 16px;

    @media @screen-gt-xs {
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        font-size: 22px;
    }
}

:global(.archived-vacancy-card) .vacancy-name {
    color: @color-gray-50;
}

.premium-container {
    position: relative;
    white-space: nowrap;
    margin-left: @spacing-base * 2;
    top: 2px;
    --magritte-ui-icon-color-override: @magritte-color-base-red-60;

    svg [fill] {
        /* stylelint-disable-next-line declaration-no-important */
        fill: @magritte-color-base-red-60 !important;
    }

    &::before {
        content: '\200B';
    }

    @media @screen-gt-xs {
        top: 1px;
    }
}
