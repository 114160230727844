@import '~bloko/common/styles/colors';

.company-info-text {
    color: @color-special-absolute-black;
    overflow-wrap: break-word;
}

:global(.archived-vacancy-card) .company-info-text {
    color: @color-gray-50;
}
