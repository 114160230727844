@import '~bloko/common/styles/text-base';
@import '~bloko/common/styles/media/_screen';

.compensation-text {
    .bloko-text-large();

    vertical-align: middle;

    @media @screen-gt-xs {
        /* stylelint-disable-next-line declaration-property-value-allowed-list */
        font-size: 18px;
    }
}
