/* stylelint-disable declaration-property-value-disallowed-list */
/* stylelint-disable declaration-property-value-allowed-list */

/* disabling stylelint rules for the file because we are doing fake magritte on bloko here */
@import '~bloko/common/styles/rounded-corner-base';
@import '~bloko/common/styles/colors';
@import '~bloko/common/styles/media/_screen';
@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/text-base';
@import '@hh.ru/magritte-ui/tokens';
@import '@hh.ru/magritte-design-tokens/base-variables';

.vacancy-card {
    width: 100%;
}

.vacancy-info {
    padding: @magritte-static-space-300;
}

.employer-online {
    color: @color-green-50;
}

.vacancy-archived {
    float: left;
    background-color: @magritte-color-background-primary;
    padding: @spacing-base * 3 @spacing-base * 4;
    border-radius: @rounded-corner-default;
    margin-top: @spacing-base * 6;

    @media @screen-gt-xs {
        margin-top: @spacing-base * 4;
        background-color: @magritte-color-background-secondary;
    }
}

.compensation-labels {
    display: flex;
    flex-wrap: wrap;
    line-height: 24px;
    gap: @spacing-base * 2;

    @media @screen-gt-xs {
        gap: @spacing-base * 2 @spacing-base * 3;
    }
}

.compensation-labels_magritte {
    gap: @spacing-base * 2 @spacing-base * 3;
}

.favorite-container {
    float: right;
}

.archived-text {
    .bloko-text-tertiary();

    vertical-align: middle;
}
