@import '../../../../bloko/common/styles/colors';

@photo-size: 44px;
@photo-border-radius: 30px;

.rounded-pic() {
    width: @photo-size;
    height: @photo-size;
    border-radius: @photo-border-radius;
}

.resume-info {
    display: flex;
}

.photo {
    .rounded-pic();
}

.photo_empty {
    background: @color-gray-30;
}

.avatar {
    .rounded-pic();

    background: url('images/avatars.svg') 0 0;
}

.avatar_male {
    background-position: -@photo-size 0;
}

.avatar_female {
    background-position: -2 * @photo-size 0;
}
