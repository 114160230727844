@import '~bloko/common/styles/media/_screen';

:global(.serp-item_link:hover) .serp-item-controls-item {
    @media @screen-gt-xs {
        opacity: 1;
    }
}

.serp-item-controls-item {
    @media @screen-gt-xs {
        opacity: 0;

        &:focus-within {
            opacity: 1;
        }
    }
}

.blacklist-mobile-container {
    float: right;
    clear: right;
}
