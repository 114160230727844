@import 'text-base';
@import 'media/_screen';

// Все переменные ниже запрещено использовать извне,
// предназначены только для внутреннего использования в Блоко в этом файле
@_header-font-family-fallback: ProximaNovaCond, 'Arial Narrow', 'Roboto Condensed', Arial, sans-serif;
@_header-font-family: var(--bloko-font-family-header-override, @_header-font-family-fallback);

@_header-font-size: 32px 24px 18px 14px;
@_header-line-height: 1.12 1.16 1.1 1.4;
@_header-font-size-xs: 24px 20px 16px 14px;
@_header-line-height-xs: 1.16 1.2 1.24 1.4;

@_header-promo-font-size: 92px 64px 44px 32px;
@_header-promo-line-height: 1 1 1 1.12;
@_header-promo-font-size-xs: 52px 40px 32px 24px;
@_header-promo-line-height-xs: 1 1 1 1.16;

@_header-subheader-font-size: 16px;
@_header-subheader-line-height: 1.4;

/* stylelint-disable-next-line selector-class-pattern */
._header-base(@level, @type: false) {
    @suffix: if(isstring(@type), ~'-@{type}', '');

    @font-size: ~'_header@{suffix}-font-size';
    @line-height: ~'_header@{suffix}-line-height';

    @font-size-xs: ~'_header@{suffix}-font-size-xs';
    @line-height-xs: ~'_header@{suffix}-line-height-xs';

    margin: 0;
    padding: 0;

    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: extract(@@font-size, @level);
    line-height: extract(@@line-height, @level);

    @media @screen-lt-s {
        :global(.xs-friendly) & {
            /* stylelint-disable-next-line declaration-property-value-allowed-list */
            font-size: extract(@@font-size-xs, @level);
            line-height: extract(@@line-height-xs, @level);
        }
    }
}

.bloko-header-base(@level, @isLite: false) {
    ._header-base(@level);

    @font-weight: if(@isLite, normal, bold);

    font-family: @_header-font-family;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-weight: @font-weight;
}

.bloko-header-section-base(@level, @isLite: false) {
    ._header-base(@level);

    @font-weight: if(@isLite, normal, bold);

    font-family: @_font-family;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-weight: @font-weight;
}

.bloko-header-promo-base(@level, @isLite: false) {
    ._header-base(@level, 'promo');

    @font-weight: if(@isLite, bold, 800);

    font-family: @_header-font-family;
    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-weight: @font-weight;
}

.bloko-header-subheader-base() {
    margin: 0;
    padding: 0;

    /* stylelint-disable-next-line declaration-property-value-allowed-list */
    font-size: @_header-subheader-font-size;
    line-height: @_header-subheader-line-height;

    font-weight: normal;
    font-family: @_font-family;

    color: @color-gray-60;
}
