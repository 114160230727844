@import '../../blocks/modal/modal-mixins';
@import '../../common/styles/media/_screen';
@import '../../common/styles/_abstract-modal';
@import '../../common/styles/_abstract-control';
@import '../../common/styles/_layers';
@import '../../common/styles/_indent-variables';
@import '../../common/styles/colors';
@import '../../common/styles/header-base';
@import '../../common/styles/rounded-corner-base';

// Время выполнения анимации появления модала
@transition-duration: 150ms;

// ширина свайпера, который закрывает bottom-sheet
@swiper-width: 36px;

@bloko-modal-content-fader-bottom-height: 30px;

// Для страниц, неадаптированных под мобильный экран
// т.е. без класса xs-friendly
// мы должны оставить скролл, иначе
// будет невозможно пользоваться модальным окном

.bloko-modal-no-scroll {
    @media @screen-gt-xs {
        .modal-no-scroll();

        &_ios {
            .modal-no-scroll-ios();
        }
    }
}

:global(.xs-friendly).bloko-modal-no-scroll {
    .modal-no-scroll();
}

:global(.xs-friendly).bloko-modal-no-scroll_ios {
    .modal-no-scroll-ios();
}

.bloko-modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: @overlay-content + 1;
    background-color: transparent;
    visibility: hidden;
    opacity: 0;
    transition-property: background-color, visibility, opacity;
    transition-duration: @transition-duration;
    transition-timing-function: linear;
}

.bloko-modal-overlay_visible {
    background-color: fade(@color-gray-80, 90%);
    visibility: visible;
    opacity: 1;
}

.bloko-modal-overlay__bottom-sheet {
    transition-duration: 500ms;

    @media @screen-gt-xs {
        transition-duration: @transition-duration;
    }
}

.bloko-modal-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 100%;
    box-sizing: border-box;
    z-index: @overlay-content + 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    transition: opacity 150ms linear;
    opacity: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media @screen-gt-xs {
        padding: @modal-container-padding;
    }
}

.bloko-modal-container_visible {
    opacity: 1;
}

.bloko-modal {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    max-width: 100%;
    z-index: @overlay-content + 2;
    user-select: text;
    background-color: @color-white;
    box-sizing: border-box;
    box-shadow: 0 10px 15px 0 fade(@color-gray-80, 40%);
    margin: auto;
    padding: @modal-padding;
    overflow: auto;

    @media @screen-gt-xs {
        border-radius: @rounded-corner-default;
    }
}

.bloko-modal-close-button {
    position: fixed;
    top: @modal-cross-size;
    right: @modal-cross-size;
    z-index: @overlay-content + 4;
    cursor: pointer;

    &:active {
        top: @modal-cross-size + 1px;
    }

    &::after {
        content: '';
        display: inline-block;
        width: @modal-cross-size;
        height: @modal-cross-size;
        background: url('close-button.svg') no-repeat 0 0;
        background-size: 200%;
    }
}

.bloko-modal-close-button_hidden {
    display: none;
}

.bloko-modal-header {
    width: 0;
    min-width: 100%;
    margin-bottom: @modal-header-bottom-margin;
    flex-shrink: 0;
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.bloko-modal-content {
    flex-grow: 1;

    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .abstract-control-focus-visible-clip-container();
}

.bloko-modal-content_no-scroll {
    overflow: hidden;
    position: relative;
    -webkit-overflow-scrolling: unset;
    .abstract-control-focus-visible-clip-container();

    margin-bottom: @control-box-shadow-focus-visible-width;
}

.bloko-modal-content-scrollable {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    .abstract-control-focus-visible-clip-container();

    padding-right: @scrollbar-max-with;
}

.bloko-modal-content-scrollable-children {
    height: 100%;
}

.bloko-modal-content-fader {
    position: absolute;
    bottom: -50px;
    left: 0;
    right: 0;
    height: 40px;
    margin-right: 18px;
    background: linear-gradient(0deg, @color-white 1%, fade(@color-white, 0%) 99%);
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease;
}

.bloko-modal-content-fader_visible {
    opacity: 1;
    bottom: 0;
}

.bloko-modal-content-fader-bottom {
    position: relative;
    margin: 0 -@modal-padding -@modal-footer-top-margin;
    min-height: @bloko-modal-content-fader-bottom-height;
    border-top: 1px solid @color-gray-50;
    text-align: center;
    background: @color-white;
    pointer-events: none;
    opacity: 0;
    transition: all 0.2s ease;
}

.bloko-modal-content-fader-bottom_visible {
    opacity: 1;
}

.bloko-modal-footer {
    width: 0;
    min-width: 100%;
    text-align: right;
    margin-top: @modal-footer-top-margin;
    flex-shrink: 0;
}

.bloko-modal-footer_left {
    text-align: left;
}

.bloko-modal-title {
    .bloko-header-base(2);
}

.bloko-modal-error {
    height: auto;
    overflow: hidden;
    color: @color-white;
    background: @color-red-40;
    padding: 7px @modal-padding;
    margin: @vertical-indent -@modal-padding 0;
    transition: padding 0.25s ease;
    flex-shrink: 0;
}

.bloko-modal-error_hidden {
    height: 0;
    overflow: hidden;
    margin-top: 0;
    padding: 0 @modal-padding;
}

.bloko-modal-swipe {
    position: absolute;
    z-index: @overlay-content + 3;
    width: 100%;
    height: 25px;
    text-align: center;

    &::after {
        content: '';
        position: absolute;
        top: 8px;
        margin-left: -(@swiper-width / 2);
        width: @swiper-width;
        height: 4px;
        background: @color-gray-20;
        border-radius: 8px;
    }

    @media @screen-gt-xs {
        display: none;
    }
}

@media @screen-lt-s {
    :global(.xs-friendly) {
        .bloko-modal-container {
            transition: none;
        }

        .bloko-modal {
            position: relative;
            min-width: @modal-min-width-xs;
            width: 100%;
            height: 100%;
            max-height: none;
            flex-shrink: 0;
            background: @color-white;
            padding: @modal-vertical-padding-xs @modal-padding-xs;
        }

        .bloko-modal-container_bottom-sheet {
            transition: top 500ms;
            min-height: auto;
            top: auto;
            border-radius: 12px 12px 0 0;
        }

        .bloko-modal-container_bottom-sheet .bloko-modal {
            padding: @modal-container-padding @modal-padding-xs;
        }

        .bloko-modal-container_bottom-sheet .bloko-modal-content_no-scroll {
            margin-bottom: 0;
        }

        .bloko-modal-container_bottom-sheet .bloko-modal-footer {
            margin-top: 0;
        }

        .bloko-modal-container_bottom-sheet .bloko-modal-content-fader-bottom {
            margin: 0 -@modal-padding-xs (@modal-container-padding - @modal-vertical-padding-xs);
            min-height: 0;
            border-top: 0;
        }

        .bloko-modal-bottom-sheet-fader {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 10px;
            background: linear-gradient(0deg, @color-gray-10 1%, fade(@color-white, 0%) 99%);
            opacity: 0;
            transition: opacity 0.2s ease;
        }

        .bloko-modal-bottom-sheet-fader_visible {
            opacity: 1;
        }

        .bloko-modal-close-button {
            .bloko-header-base(2);

            position: absolute;
            top: 0;
            right: 0;
            padding: @modal-vertical-padding-xs @modal-padding-xs;

            &::after {
                width: @modal-cross-size-xs;
                height: @modal-cross-size-xs;
                background-position: 100% 0;
            }
        }

        .bloko-modal-header {
            margin-bottom: @modal-vertical-padding-xs;
        }

        .bloko-modal-header_outlined::after {
            content: '';
            display: block;
            margin: @modal-vertical-padding-xs -@modal-padding-xs 0;
            border-bottom: 1px solid @color-gray-20;
        }

        .bloko-modal-title {
            &::before {
                content: '';
                display: block;
                float: right;
                width: @modal-cross-size-xs + @modal-padding-xs;
                height: @modal-cross-size-xs;
            }

            &::after {
                content: '';
                display: table;
                clear: both;
            }
        }

        .bloko-modal-footer {
            margin-bottom: -@modal-vertical-padding-xs;
            padding: @modal-vertical-padding-xs 0;
        }

        .bloko-modal-error {
            margin: @bloko-modal-content-fader-bottom-height -@modal-padding-xs 0 -@modal-padding-xs;
        }

        .bloko-modal-error_hidden {
            margin-top: 0;
        }

        .bloko-modal-content-fader-bottom {
            margin-left: -@modal-padding-xs;
            margin-right: -@modal-padding-xs;
        }
    }
}
