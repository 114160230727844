@import '~bloko/common/styles/spacing-base';
@import '~bloko/common/styles/media/_screen';
@import '@hh.ru/magritte-ui/tokens';

.controls-container {
    display: flex;
    align-items: center;
}

.controls-spacer {
    margin-left: @spacing-base * 2;

    @media @screen-gt-xs {
        margin-left: @spacing-base * 3;
    }
}

.archived {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    background-color: @magritte-color-background-secondary;
    border-radius: @magritte-static-border-radius-300;
}

.workflow-status-container {
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    height: 48px;
    border-radius: @magritte-static-border-radius-300;
}

.workflow-status-container-responded {
    background-color: @magritte-color-background-secondary;
}

.workflow-status-container-invited {
    background-color: @magritte-color-background-positive;
}

.workflow-status-container-discarded {
    background-color: @magritte-color-background-warning;
}

.after-response-button-mobile {
    flex: 1;
}
